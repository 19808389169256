import React from 'react';
import Image from 'next/image';
import { Tooltip } from '@mui/material';

function TooltipIcon({ src, Icon, alt }) {
  return (
    <Tooltip title={alt}>
      {/* Can't do this as 2 separate &&'s because Tooltip throws a fit. */}
      {src ? <Image priority src={src} alt={alt} /> : <Icon />}
    </Tooltip>
  );
}

export default TooltipIcon;
